import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { fetchAthleticsHelper } from './athletics.async';
import { fetchArticlesHelper } from './news.async';
import { fetchLiveFeedHelper } from './livefeed.async';
import { fetchStaffHelper } from './staff.async';

export async function fetchFilters(prevFilters, { url, params }, fetch) {
  let filters = prevFilters;

  // don't refetch filters if they have been previously fetched
  if (isEmpty(filters)) {
    const responseFilters = await fetch.get(url, { params: { ...params }, cache: { ignoreCache: false } });
    filters = responseFilters.data.meta.filters;
  }
  return filters;
}

function generateAsyncFetchFunction({ type, fallbackSectionName, paths }, sectionHelperFunction) {
  return async function fetch({ dispatch, getters, commit }, queryParams, fetcher, $t) {
    await dispatch('setCustomSectionData', { type, fallbackSectionName });

    const currFilters = getters.getCustomSectionFilters({ type });
    const sectionUrl = getters.getCustomSectionApi(fetcher.defaults.baseURL, paths);
    const filters = await fetchFilters(currFilters, sectionUrl, fetcher);
    commit('setDynamicPageFilters', { type, filters });

    return sectionHelperFunction(filters, sectionUrl, queryParams, fetcher, $t);
  };
}

export async function setupAndFetchAthletics(store, queryParams, fetcher) {
  const sectionPaths = store.getters.getCustomSectionPaths;
  const fetchAthletics = generateAsyncFetchFunction(
    {
      type: 'scores_schedules',
      fallbackSectionName: 'Athletics',
      paths: get(sectionPaths, 'v4.athletics.main', sectionPaths.athletics),
    },
    fetchAthleticsHelper,
  );
  return fetchAthletics(store, queryParams, fetcher);
}

export function setupAndFetchArticles(store, queryParams, fetcher) {
  const sectionPaths = store.getters.getCustomSectionPaths;
  const fetchArticles = generateAsyncFetchFunction(
    {
      type: 'articles',
      fallbackSectionName: 'News',
      paths: sectionPaths.news,
    },
    fetchArticlesHelper,
  );
  return fetchArticles(store, queryParams, fetcher);
}

export function setupAndFetchLiveFeed(store, queryParams, fetcher, $t) {
  const sectionPaths = store.getters.getCustomSectionPaths;
  const fetchLiveFeed = generateAsyncFetchFunction(
    {
      type: 'live_feeds',
      fallbackSectionName: 'Live Feed',
      paths: sectionPaths.live_feed,
    },
    fetchLiveFeedHelper,
  );
  return fetchLiveFeed(store, queryParams, fetcher, $t);
}

export function setupAndFetchStaff(store, queryParams, fetcher) {
  const sectionPaths = store.getters.getCustomSectionPaths;
  const fetchStaff = generateAsyncFetchFunction(
    {
      type: 'directories',
      fallbackSectionName: 'Staff',
      paths: get(sectionPaths, 'v4.staff.main', sectionPaths.staff),
    },
    fetchStaffHelper,
  );
  return fetchStaff(store, queryParams, fetcher);
}
