import { extractUrlParams } from '../pagination';

export async function fetchAthleticsHelper(filters, { url, params }, queryParams = {}, fetch) {
  if (queryParams.filter_ids) {
    // eslint-disable-next-line no-param-reassign
    queryParams.filter_ids = queryParams.filter_ids.split ? queryParams.filter_ids.split(',') : queryParams.filter_ids;
  }

  const response = await fetch.get(url, { params: { ...params, ...queryParams }, cache: { ignoreCache: false } });
  const {
    scores_schedules: games,
    meta: { links },
  } = response.data;
  const { params: lastPageParams } = extractUrlParams(links.last);

  const sortedGames = games.sort((a, b) => new Date(a.formatted_date) - new Date(b.formatted_date));

  return {
    games: sortedGames,
    filters: filters || [],
    totalPages: +lastPageParams.page_no,
    currentPage: +queryParams.page_no || 1,
  };
}
