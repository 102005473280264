import { extractUrlParams } from '../pagination';

// eslint-disable-next-line import/prefer-default-export
export async function fetchStaffHelper(filters, { url, params }, queryParams = {}, fetch) {
  let formattedUrl = url;
  if (queryParams.id) {
    formattedUrl = `${url}/${queryParams.id}`;
  }

  const filter_ids = queryParams.filter_ids && queryParams.filter_ids.split ? queryParams.filter_ids.split(',') : '';
  const response = await fetch
    .get(formattedUrl, {
      params: {
        ...params,
        ...queryParams,
        filter_ids,
      },
      cache: { ignoreCache: false },
    })
    .catch(async (e) => fetch.get(url, { params: { ...params, ...queryParams, filter_ids } }));

  if (response.data.directory) {
    return {
      staff: [response.data.directory],
      filters,
      totalPages: 0,
      total_entries: 1,
      currentPage: 1,
      memberName: response.data.directory.full_name,
    };
  }

  const {
    directories: staff,
    meta: { links },
  } = response.data;
  const { params: lastPageParams } = extractUrlParams(links.last);

  const staffUnique = staff.reduce((unique, next) => {
    if (!unique.some((obj) => obj.id === next.id)) {
      unique.push(next);
    }
    return unique;
  }, []);
  const { search } = queryParams;

  return {
    staff: staffUnique,
    filters,
    totalPages: +lastPageParams.page_no,
    total_entries: links.total_entries,
    currentPage: +queryParams.page_no || 1,
    search,
  };
}
