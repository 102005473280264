import { extractUrlParams } from '../pagination';
import { getTimeAgoStringFromUTC } from '../time-ago-in-words.js';

export async function fetchLiveFeedHelper(filters, { url, params }, queryParams = {}, fetcher, $t) {
  const [firstFilter] = filters;
  const selectedFilter = queryParams.filter_id || (firstFilter && firstFilter.id);

  const response = await fetcher.get(url, {
    params: {
      page_no: Number(queryParams.page_no) || undefined,
      page_size: Number(queryParams.page_size) || undefined,
      filter_id: Number(selectedFilter) || undefined,
      locale: params.locale,
      max_images: 10,
    },
    cache: { ignoreCache: false },
  });

  const {
    live_feeds,
    meta: { links },
  } = response.data;

  const feeds = generateDynamicTimeAgoValues(live_feeds, $t);
  const { params: lastPageParams } = extractUrlParams(links.last);

  return {
    feeds,
    filters,
    selectedFilter,
    totalPages: +lastPageParams.page_no,
    currentPage: +queryParams.page_no || 1,
  };
}

const generateDynamicTimeAgoValues = (liveFeeds, $t) => {
  return liveFeeds?.map((lf) => {
    return {
      ...lf,
      time_ago: getTimeAgoStringFromUTC(lf.time, $t),
    };
  });
};
