import { extractUrlParams } from '../pagination';

export async function fetchArticlesHelper(filters, { url, params }, queryParams = {}, api) {
  const [firstFilter] = filters;
  const selectedFilter = queryParams.filter_id || (firstFilter && firstFilter.id);

  const response = await api.get(url, {
    params: {
      ...params,
      ...queryParams,
      filter_id: selectedFilter,
    },
    cache: { ignoreCache: false },
  });
  const {
    articles: news,
    meta: { links },
  } = response.data;
  const { params: lastPageParams } = extractUrlParams(links.last);

  return {
    news,
    filters,
    selectedFilter,
    totalPages: +lastPageParams.page_no,
    currentPage: +queryParams.page_no || 1,
  };
}
